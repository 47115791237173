import { assocPath, compose, sortBy, merge, filter, isEmpty, path } from 'ramda';
import CONST from '../constants/wallet-constants';
import { getConvertibleTotal, balance } from '../utils/send-money-utils';
import Decimal from 'decimal.js';
import { find, propEq } from 'ramda';
import { hasFeature } from '../utils/permissions';
const INIT_STATE = {
  wallet: {},
  bankAccounts: [],
  cardAccounts: [],
  accounts: [],
  errorMessage: null,
  notAsked: true,
  isLoading: false,
  isUpdating: false,
  transactionRate: {},
  currencyPromos: [],
};

export const isInvoicingEnabledSelector = (state) => {
  const { wallet: { wallet: { features } } } = state;

  return !!features && !isEmpty(features) ?
    hasFeature(features, 'invoicing_enabled') : false;
};

export const isReceivingFundsEnabledSelector = (state) => {
  const { wallet: { wallet: { features } } } = state;

  return !!features && !isEmpty(features) ?
    hasFeature(features, 'can_receive_funds') : false;
};

const walletReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_FIELD:
      return assocPath([action.payload.path], action.payload.value, state);
    case CONST.GET_CURRENT_WALLET:
      return merge(state, {
        notAsked: false,
        isLoading: true,
      });
    case CONST.SET_WALLET_BANK_ACCOUNTS:
      return assocPath(
        ['bankAccounts'],
        sortBy(a => a.updated_at, action.payload.bankAccounts),
      )(state);
    case CONST.SET_WALLET_CARD_ACCOUNTS:
      return compose(
        assocPath(
          ['cardAccounts'],
          sortBy(a => a.updated_at, action.payload.cardAccounts),
        ),
        assocPath(
          ['cardAccountsActive'],
          filter((card) => card.status === 'active' && card.verified === true, action.payload.cardAccounts),
        )
      )(state);
    case CONST.SET_WALLET_ACCOUNTS:
      return assocPath(
        ['accounts'],
        sortBy(a => a.type, action.payload.accounts),
      )(state);
    case CONST.SET_CURRENT_WALLET:
      return compose(
        assocPath(['isLoading'], false),
        assocPath(['isUpdating'], false),
        assocPath(['wallet'], action.payload.wallet),
      )(state);
    case CONST.UPDATE_WALLET_HANDLE:
    case CONST.UPDATE_TRANSACTION_ALLOCATION:
      return compose(
        assocPath(['isUpdating'], true),
        assocPath(['errorMessage'], INIT_STATE.errorMessage),
      )(state);
    case CONST.GET_RATE:
      return assocPath(['isLoading'], true, state);
  
    case CONST.SET_RATE:
      return compose(
        assocPath(['isLoading'], false),
        assocPath(['transactionRate'], action.payload.rate),
      )(state);
    case CONST.SET_CURRENCY_PROMOS:
      return assocPath(['currencyPromos'], action.payload.currencyPromos, state);
    default:
      return state;
  }
};

export default walletReducer;

export const userBalanceSelector = (state) => {
  const { transactionRate, accounts } = state.wallet;

  return transactionRate && Decimal(
    accounts.reduce(
      (result, account) => Decimal(result).plus(
        !!path([account.balance.currency, 'USD'], transactionRate) 
          ? getConvertibleTotal(account, transactionRate) 
          : balance(path(['balance', 'ordinal'], account), path(['balance', 'decimal'], account),  path(['balance', 'sign'], account))
      ), 0)
  ).toFixed(2);
};

export const maxBalanceAccountSelector = ({ wallet: { transactionRate, accounts } }) => !isEmpty(transactionRate) && accounts.reduce(
  (result, account) => !!result 
    ? getConvertibleTotal(account, transactionRate) > getConvertibleTotal(result, transactionRate) ? account : result 
    : account, null);

export const minBalanceAccountSelector = ({ wallet: { transactionRate, accounts } }) => !isEmpty(transactionRate) && accounts.reduce(
  (result, account) => !!result 
    ? getConvertibleTotal(account, transactionRate) < getConvertibleTotal(result, transactionRate) ? account : result 
    : account, null);

export const defaultAccountSelector = ({ wallet: { accounts } }) => Array.isArray(accounts) && accounts.length > 0 ?
  find(propEq('isDefault', true))(accounts) :
  null;