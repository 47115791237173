import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { TransactionShape, EnabledStatesShape, sendMoneyReducerTypes, walletReducerTypes, CurrencyOptionShape } from '../constants/prop-types';
import moment from 'moment';
import PropTypes from 'prop-types';
import { path, prop, propEq, find, pathEq, compose } from 'ramda';
import { trackEvent } from '../utils/ganalytics';
import cn from 'classnames';
import Badge from './badge';
import Modal from './modal';
import TransactionResult from './transaction-result';
import Button from './button';
import { formatTransaction, capitalize, isZeroValue } from '../utils/string-utils';
import {
  getTransactionType,
  getIsIncoming,
  getWalletToShow,
  findRejectedBA,
  formatTransactionTimeDate,
  getClearDate,
} from '../utils/transaction-utils';
import { replaceUrl } from './transactions-list';

import CONST from '../constants/transactions-constants';
import * as SEND_MONEY_CONST from '../constants/send-money-constants';
import { _convertCurrency, withCurrency, balance } from '../utils/send-money-utils';
import Loader from './loader';
import ModalReportTransaction from './modal-report-transaction';
import ModalRefundTransaction from './modal-refund-transaction';
import ModalRetryTransaction from './modal-retry-transaction';
import { report, setField } from '../actions/transactions-actions';
import { makeRefund, setField as setSendMoneyField } from '../actions/send-money-actions';
import NegativeBalanceBadge from './negative-balance-badge';
import { hasPermission } from '../utils/permissions';
import { userBalanceSelector } from '../reducers/wallet-reducer';
import { currencyOptionsSelector } from '../reducers/app-reducer';

const {
  TRANSACTION_STATUS_COLOR,
  MDASH,
  HUMAN_READABLE_STATUSES,
} = CONST;

const TransactionDetail = ({ detail, accounts }) => {
  const accountCurrency = path(['account_amount', 'currency'], detail);
  const transactionCurrency = path(['transaction_amount', 'currency'], detail);
  const { status } = detail;
  const isBankAccount = !!propEq('account_type', 'bankaccount', detail);
  const isFee = detail.type === 'fee';
  const isReward = detail.type === 'reward';
  const isRateModifier = detail.type === 'rate_modifier';
  const accountName = compose(
    prop('name'),
    find(
      pathEq(['balance', 'currency'], accountCurrency)
    )
  )(accounts);
  const amount = `${balance(
    path(['account_amount', 'ordinal'], detail),
    path(['account_amount', 'decimal'], detail),
    path(['account_amount', 'sign'], detail
    ))}`;
  const rate = { [accountCurrency]: { [transactionCurrency]: path(['transaction_account_exchange_rate', 'rate'], detail) } };

  return (
    <div className="description-list_group">
      <div className="description-list_item">
        <div className="description-list_title word-break-word">
          {isReward && 'Reward Points'}
          {isFee && 'Fee'}
          {isBankAccount && 'Bank Account'}
          {isRateModifier && 'Credit'}
          {!isReward && !isFee && !isBankAccount && !isRateModifier && accountName + ' Account'}
        </div>
        <div className="description-list_value">
          <div 
            id={`js-transaction-result-details-${isBankAccount ? 'bank-account' : accountCurrency.toLowerCase()}-text`} 
            className="description-list_item-primary"
          >
            {formatTransaction(prop('account_amount', detail))}
          </div>
          {accountCurrency !== transactionCurrency && (
            <div className="description-list_item-secondary">
              {withCurrency(_convertCurrency(accountCurrency, transactionCurrency, amount, rate), transactionCurrency)}
            </div>
          )}
        </div>
      </div>
      <div className="description-list_item">
        <div className="description-list_title">Status</div>
        <div 
          id={`js-transaction-result-details-${isBankAccount ? 'bank-account' : accountCurrency.toLowerCase()}-status-text`}
          className={`description-list_value font-weight-bold color-${TRANSACTION_STATUS_COLOR[status]}`}
        >
          {HUMAN_READABLE_STATUSES[status]}
        </div>
      </div>
      {status === CONST.TRANSACTION_STATUSES.FAILED && (
        <div className="description-list_item">
          <div className="description-list_title">Description</div>
          <div className={`description-list_value font-weight-bold color-${TRANSACTION_STATUS_COLOR[status]}`}>
            {detail.description}
          </div>
        </div>
      )}
    </div>
  );
};

TransactionDetail.propTypes = {
  detail: PropTypes.shape(),
  accounts: walletReducerTypes.accounts
};

const TransactionModal = (props) => {
  const [showReportTransaction, setShowReportTransaction] = useState(false);
  const [showRefundTransaction, setShowRefundTransaction] = useState(false);
  const [showRetryTransaction, setShowRetryTransaction] = useState(false);
  const { shown,
    accounts,
    setField,
    sendMoney,
    transaction,
    permissions,
    userBalance,
    reportIsSending,
    reportConfirmed,
    setSendMoneyField,
    transactionsPending,
    transactionsCompleted,
    refundConfirmationModalShown,
  } = props;

  const showTransaction = transaction => evt => {
    evt.preventDefault();

    if (setField && transaction) {
      setField('transactionShown', false);
      replaceUrl(transaction.transaction_id);
  
      trackEvent('transaction_view','View transaction details');
  
      setTimeout(() => {
        setField('transaction', transaction);
        setField('transactionShown', true);
      }, 400);
    }
  };

  const renderBadge = () => {
    const type = getTransactionType(transaction);
    const rejectedBA = findRejectedBA(transaction);
    const clearDate = getClearDate(transaction);
    const getRelativeId = ({ transaction_id, transaction_relatives }) => {
      const { child_id, parent_id } = transaction_relatives[0];

      return transaction_id === parent_id ? child_id : parent_id;
    };

    // Failed
    if (transaction.status === CONST.TRANSACTION_STATUSES.FAILED) {
      if (type === CONST.TRANSFER && rejectedBA) {
        return <Badge status="danger">Transaction failed. Bank transfer failed.</Badge>;
      }
      // Failed reconciliated floated order
      if(
        (type === CONST.ORDER || type === CONST.TRANSFER) &&
        Array.isArray(transaction.transaction_relatives) &&
        transaction.transaction_relatives.length > 0 &&
        transaction.transaction_relatives[0].type === CONST.TRANSACTION_RELATIVES_TYPES.RECONCILE
      ) {
        const relativeTransactionId = getRelativeId(transaction);
        const transactionToShow = transactionsCompleted.find(t => t.transaction_id === relativeTransactionId);

        return (
          <Badge status="danger">
              Transaction failed.&nbsp;
            <a className="cursor-pointer" onClick={showTransaction(transactionToShow)}>Reconciliation transaction created.</a>
          </Badge>
        );
      }

      // Failed retried non-floated order
      if(
        (type === CONST.ORDER || type === CONST.TRANSFER) &&
        Array.isArray(transaction.transaction_relatives) &&
        transaction.transaction_relatives.length > 0
      ) {
        const relativeTransactionId = getRelativeId(transaction);
        let transactionToShow = transactionsCompleted.find(t => t.transaction_id === relativeTransactionId);

        if(!transactionToShow) {
          transactionToShow = transactionsPending.find(t => t.transaction_id === relativeTransactionId);
        }

        return (
          <Badge status="danger">
            Transaction failed.&nbsp;
            <a className="cursor-pointer" onClick={showTransaction(transactionToShow)}>Payment resubmitted. See Details</a>
          </Badge>
        );
      }

      return <Badge status="danger">Transaction failed.</Badge>;
    }

    // Suspended
    if (transaction.status === CONST.TRANSACTION_STATUSES.SUSPENDED) {
      return (
        <Badge status="alert">
          Transaction suspended. Please contact
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={process.env.REACT_APP_HELP_DESK_URL}
          >
            {' Customer Support '}
          </a>for more details.
        </Badge>
      );
    }

    // Pending
    if (transaction.status === CONST.TRANSACTION_STATUSES.PENDING) {
      // Pending retry for failed non-floated transaction
      if(Array.isArray(transaction.transaction_relatives) && transaction.transaction_relatives.length > 0) {
        const relativeTransactionId = getRelativeId(transaction);

        let transactionToShow = transactionsCompleted.find(t => t.transaction_id === relativeTransactionId);
        if(!transactionToShow) {
          transactionToShow = transactionsPending.find(t => t.transaction_id === relativeTransactionId);
        }

        return (
          <Badge status="pending">
            Transaction&nbsp;
            <span className="lowercase">{HUMAN_READABLE_STATUSES[transaction.status]}.</span>&nbsp;
            <a className="cursor-pointer" onClick={showTransaction(transactionToShow)}>Resubmission of failed payment</a>
          </Badge>
        );
      }

      return (
        <Badge status="pending">
          Transaction pending.<br />
          { clearDate ? `Clears on ${clearDate}.` : ''}
        </Badge>
      );
    }

    // Successful reconciliation or retry
    if(
      transaction.status === CONST.TRANSACTION_STATUSES.COMPLETE &&
      Array.isArray(transaction.transaction_relatives) &&
      transaction.transaction_relatives.length > 0
    ) {
      const relativeTransactionId = getRelativeId(transaction);
      const transactionToShow = transactionsCompleted.find(t => t.transaction_id === relativeTransactionId);
  
      return (
        <Badge status="success">
          Transaction&nbsp;
          <span className="lowercase">{HUMAN_READABLE_STATUSES[transaction.status]}.</span>&nbsp;
          {type === CONST.REFUND || !transactionToShow || transactionToShow.type === CONST.REFUND ? '' : type === CONST.RECONCILIATION ?
            (
              <span>Reconciliation for <a className="cursor-pointer" onClick={showTransaction(transactionToShow)}>failed ACH transfer</a></span>
            ) : (
              <span>Retry for <a className="cursor-pointer" onClick={showTransaction(transactionToShow)}>failed transaction</a></span>
            )}
          
        </Badge>
      );
    }

    return (
      <Badge status="success">
        Transaction&nbsp;
        <span className="lowercase">{HUMAN_READABLE_STATUSES[transaction.status]}.</span>
        <br />
        { clearDate ? `Cleared on ${clearDate}.` : ''}
      </Badge>
    );
  };

  const isRetryable = () => {
    if(transaction) {
      const { type, status, transaction_details, transaction_relatives } = transaction;

      const noRelatives = !transaction_relatives ||
        transaction_relatives.every(
          relative => relative.type !== CONST.TRANSACTION_RELATIVES_TYPES.RECONCILE && 
            relative.parent_id !== transaction.transaction_id
        );

      return type === 'order' && noRelatives && 
        (status === 'failed' || status === 'suspended') &&
        transaction_details && Array.isArray(transaction_details.all) && transaction_details.all.some(
        (item) => item.status === 'failed' && item.account_type === 'bankaccount'
      );
    } else {
      return false;
    }
  };

  const renderBody = () => {

    if (!transaction) {
      return (<Loader size="sm" color="blue" className="-centered" />);
    }

    if (!transaction.transaction_id) {
      return (<div className="modal_wrapper">
        <div className="modal-text-wrapper">
          <h1 className="modal_header-centered">Transaction not found</h1>
          <p className="modal_body-centered">
            Transaction does not exist<br />
            or you are not allowed to see it.
          </p>
        </div> 
        
        <div className="modal_body -down">
          <p>If you are sure that the transaction should be here, it might be something wrong on our side.</p>
          <p>Try again later or contact 
            <a // eslint-disable-line jsx-a11y/anchor-is-valid
              target="_blank" 
              rel="noopener noreferrer"
              href={process.env.REACT_APP_HELP_DESK_URL}
            >
              {' Customer Support'}
            </a>
          </p>
        </div>
      </div>);
    }

    const isIncoming = getIsIncoming(transaction);
    const sign = isIncoming ? '+' : '-';
    const destination = getWalletToShow(transaction, sign);
    const type = getTransactionType(transaction);

    return (
      <div>
        <div className="modal_header">
          <h1 className="js-modal-title">Transaction Details</h1>
        </div>
        <div className="modal_body">
          <NegativeBalanceBadge withRedirect={true} />
          <div className="layer">
            <TransactionResult transaction={transaction} accounts={accounts} />

            <div className="js-transaction-result-badge layer -space-up-xs -space-down-lg">
              {renderBadge()}
            </div>

            <div className="description-list -title-floating -x-condensed">
              <div className="description-list_body">
                <div className="description-list_item">
                  <div className="description-list_title">Type</div>
                  <div className="js-transaction-result-type-text description-list_value">{capitalize(getTransactionType(transaction))}</div>
                </div>
                {/*(transaction.type === CONST.PAYMENT || transaction.type === CONST.ORDER) && */(
                  <div className="description-list_item">
                    <div className="description-list_title">{isIncoming ? 'Sender' : 'Recipient'}</div>
                    <div className="description-list_value">
                      <div className="description-list_item-primary">{destination.name}</div>
                      <div className="description-list_item-secondary">
                        {destination.user_specified_id} 
                      </div>
                    </div>
                  </div>
                )}
                <div className="description-list_item">
                  <div className="description-list_title">Amount</div>
                  <div className="js-transaction-result-amount-text description-list_value">
                    {withCurrency(Math.abs(path(['gross_amount', 'usd_equivalent'], transaction)) / 100, 'USD')}
                  </div>
                </div>
                {(isIncoming && !isZeroValue(prop('fee_amount', transaction) || {})) && (
                  <Fragment>
                    <div className="description-list_item">
                      <div className="description-list_title">Transaction Fee</div>
                      <div className="description-list_value">
                        {
                          prop('fee_amount', transaction)
                            ? formatTransaction({ ...prop('fee_amount', transaction), sign: '' })
                            : MDASH
                        }
                      </div>
                    </div>
                    <div className="description-list_item">
                      <div className="description-list_title">Net</div>
                      <div className="description-list_value">
                        {
                          prop('net_amount', transaction)
                            ? formatTransaction({ ...prop('net_amount', transaction), sign: '' })
                            : MDASH
                        }
                      </div>
                    </div>
                  </Fragment>
                )}
                {(type === CONST.PAYMENT || type === CONST.ORDER) && (
                  <div className="description-list_item">
                    <div className="description-list_title">Note</div>
                    <div className="description-list_value">
                      {transaction.description}
                    </div>
                  </div>
                )}
                <div className="description-list_item">
                  <div className="description-list_title">Status</div>
                  <div className={cn(
                    'js-transaction-result-status-text',
                    'description-list_value',
                    'font-weight-bold',
                    `color-${TRANSACTION_STATUS_COLOR[transaction.status]}`,
                  )}
                  >
                    {HUMAN_READABLE_STATUSES[transaction.status]}
                  </div>
                </div>

                <div className="description-list_item">
                  <div className="description-list_title">Transaction ID</div>
                  <div className="description-list_value">{prop('transaction_id', transaction)}</div>
                </div>

                {
                  !!prop('transaction_summary', transaction) && (
                    <div className="description-list_item">
                      <div className="description-list_title">Details</div>
                      <div className="description-list_value">{prop('transaction_summary', transaction)}</div>
                    </div>
                  )
                }

                <div className="description-list_item">
                  <div className="description-list_title">Date</div>
                  <div className="description-list_value">
                    {formatTransactionTimeDate(prop('initiated_at', transaction))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          { 
            transaction.attributes && !!transaction.attributes.length 
            && (transaction.type === CONST.PAYMENT || transaction.type === CONST.ORDER)
            && (
              <div className="layer -space-up-xxxl">
                <h2>Order Details</h2>

                <div className="description-list -title-floating -x-condensed">
                  <div className="description-list_body">
                    { transaction.attributes.map(detail => {
                      let { key, value } = detail.Name || detail.Value ? { key: detail.Name, value: detail.Value } : detail;

                      if (!key) {
                        key = detail.name;
                      }

                      if (!value) {
                        value = detail.value;
                      }

                      return (<div key={key} className="description-list_item">
                        <div className="description-list_title">{key}</div>
                        <div className="description-list_value">
                          <div className="description-list_item-primary">{value}</div>
                        </div>
                      </div>);
                    })}
                  </div>
                </div>
              </div>
            )}

          {/* <div className="layer -space-up-xxxl">
            <h2>Transaction Details</h2>
            <div className="description-list -fixed-title -title-width-lg -x-condensed">
              <div className="description-list_body">
                {
                  transactionDetailsUniq.map(detail =>
                    <TransactionDetail key={detail.transactiondetail_id} detail={detail} accounts={accounts} />)
                }
              </div>
            </div>
          </div> */}
        </div>
        <div className="modal_footer">
          <div className="modal_footer-controls">
            { transaction.type === CONST.ORDER && (
              <Button
                color="blue"
                xSize="full"
                className="js-submit-modal-button modal_footer-control -submit"
                onClick={() => setShowReportTransaction(true)}
                transparency="full"
              >
                Report transaction or the seller
              </Button>
            )}
            { transaction.is_refundable &&
              transaction.status === CONST.TRANSACTION_STATUSES.COMPLETE &&
              !!find(propEq('wallet_id', transaction.destination.wallet_id))(accounts) &&
              moment().diff(moment(transaction.initiated_at), 'days') <= 90 && (
              <Button
                color="blue"
                xSize="full"
                className="js-submit-modal-button modal_footer-control -submit"
                onClick={() => setShowRefundTransaction(true)}
                transparency="full"
                disabled={userBalance < 0}
              >
                  Refund transaction
              </Button>
            )}
          </div>
        </div>
        { isRetryable() && (
          <Button
            color="blue"
            xSize="full"
            className={cn('js-submit-modal-button modal_footer-control -submit', hasPermission(permissions, 'can_order') && '-sticky')}
            onClick={() => {
              props.setSendMoneyField('wizardStep', SEND_MONEY_CONST.CONSTANTS.STEP_ORDER_CONFIG);
              setShowRetryTransaction(true);
            }}
            transparency="full"
            disabled={!hasPermission(permissions, 'can_order')}
          >
            Retry transaction
          </Button>
        )}
      </div>
    );
  };

  const onClose = () => {
    props.onClose();
  };

  const onReportTransaction = value => {
    props.report(props.transaction.transaction_id, value);
  };

  const makeRefund = transaction => {
    props.makeRefund(transaction);
  };

  return (
    <Fragment>
      <Modal show={shown} onClose={onClose} closeButton containerClassName="-white -no-footer">
        {renderBody()}
      </Modal>
      <ModalReportTransaction
        onReportTransaction={onReportTransaction}
        reportIsSending={reportIsSending}
        reportConfirmed={reportConfirmed}
        show={showReportTransaction}
        onClose={() => {
          setShowReportTransaction(false);
          props.setField('reportConfirmed', false);
        }}
      />
      <ModalRefundTransaction
        makeRefund={makeRefund}
        setField={setSendMoneyField}
        refundConfirmationModalShown={refundConfirmationModalShown}
        transaction={transaction}
        accounts={accounts}
        sendMoney={sendMoney}
        permissions={permissions}
        show={showRefundTransaction}
        onClose={() => {
          setShowRefundTransaction(false);
          props.setField('refundConfirmationModalShown', false);
        }}
      />
      {isRetryable() && (
        <ModalRetryTransaction
          transaction={transaction}
          show={showRetryTransaction}
          onClose={() => {
            setShowRetryTransaction(false);
            props.setSendMoneyField('wizardStep', SEND_MONEY_CONST.CONSTANTS.STEP_FIND_WALLET);
            props.setSendMoneyField('foundWallet', null);
            props.setSendMoneyField('paymentAmount', '');
            props.setSendMoneyField('paymentSourceType', '');
            props.setSendMoneyField('paymentAccountId', '');
            onClose();
          }}
        />
      )}
        
    </Fragment>
  );
};

TransactionModal.propTypes = {
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  setField: PropTypes.func,
  transaction: TransactionShape,
  setSendMoneyField: PropTypes.func,
  reportIsSending: PropTypes.bool,
  reportConfirmed: PropTypes.bool,
  report: PropTypes.func,
  permissions: EnabledStatesShape,
  userBalance: PropTypes.string,
  makeRefund: PropTypes.func,
  refundConfirmationModalShown: PropTypes.bool,
  accounts: walletReducerTypes.accounts,
  sendMoney: PropTypes.shape(sendMoneyReducerTypes),
  transactionsCompleted: PropTypes.arrayOf(TransactionShape),
  transactionsPending: PropTypes.arrayOf(TransactionShape),
};

const mapStateToProps = state => ({
  reportIsSending: state.transactions.reportIsSending,
  reportConfirmed: state.transactions.reportConfirmed,
  accounts: state.wallet.accounts,
  sendMoney: state.sendMoney,
  permissions: state.user.user.enabled_states,
  userBalance: userBalanceSelector(state),
  transactionsCompleted: state.transactions.transactionsCompleted.data,
  transactionsPending: state.transactions.transactionsPending.data,
  currencyOptions: currencyOptionsSelector(state)
});

export default connect(mapStateToProps, { report, makeRefund, setField, setSendMoneyField })(TransactionModal);
