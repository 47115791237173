import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as types from '../constants/prop-types';
import { connect } from 'react-redux';
import TransactionsList from '../components/transactions-list';
import Svg from '../components/svg';
import TRANSACTIONS_CONST from '../constants/transactions-constants';
import { parse } from 'qs';
import {
  getPendingTransactions,
  getCompletedTransactions,
  getNextCompletedTransactions,
  setField,
  getTransactionDetails,
} from '../actions/transactions-actions';

const SmartTransactionsList = (props) => {
  useEffect(() => {
    if (window.location.search) {
      const params = parse(window.location.search, { ignoreQueryPrefix: true });

      if (params.transaction) {
        props.setField('transactionShown', true);
        props.getTransactionDetails(params.transaction);
      }
    } else {
      props.setField('transactionShown', false);
    }

    props.getPendingTransactions(props.filterByAccount);
    props.getCompletedTransactions(props.filterByAccount);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      return window.removeEventListener('scroll', onScroll);
    }; 
  }, [props.hasMore, props.transactionsCompleted.isLoading]);

  const onScroll = () => {
    if (window.scrollY + (document.body.clientHeight * 2) >= document.body.scrollHeight) {
      getNext();
    }
  };

  const getNext = () => {
    if (props.hasMore && props.transactionsCompleted.isLoading === false) {
      props.getNextCompletedTransactions(props.filterByAccount);
    }
  };

  const setModal = shown => {
    props.setField('transactionShown', shown);
  };

  const setTransaction = transaction => {
    props.setField('transaction', transaction);
  };

  const getNoTransactions = () => {
    const { transactionsCompleted, transactionsPending } = props;
    const completedIsLoading = transactionsCompleted.isLoading;
    const pendingIsLoading = transactionsPending.isLoading;
    const hasPendingTransactions = transactionsPending.data && transactionsPending.data.length;
    const hasCompletedTransactions = transactionsCompleted.data && transactionsCompleted.data.length;

    return !completedIsLoading &&
      !pendingIsLoading &&
      !hasCompletedTransactions &&
      !hasPendingTransactions;
  };

  const { transactionsCompleted, transactionsPending, filterByAccount } = props;
  const completedIsLoading = transactionsCompleted.isLoading;
  const pendingIsLoading = transactionsPending.isLoading;
  const noTransactions = getNoTransactions();
  const completed = transactionsCompleted.data;
  const pending = transactionsPending.data;

  return (
    <Fragment>
      <section className="dashboard_transactions transactions">
        <div className="transactions_body">
          <TransactionsList
            isSeparate={false}
            setModal={setModal}
            setTransaction={setTransaction}
            completedIsLoading={completedIsLoading}
            pendingIsLoading={pendingIsLoading}
            completed={completed}
            pending={pending}
            hasMore={props.hasMore}
            noTransactions={noTransactions}
            transactionShown={props.transactionShown}
            transactionToShow={props.transaction}
            filterByAccount={filterByAccount}
          />
          { !props.hasMore && !noTransactions && !completedIsLoading && !pendingIsLoading && (
            <div className="splash color-light-gray">
              <div
                className="js-dashboard-no-transactions-text splash_message font-size-secondary-responsive"
              >
                No more transactions to show<br/>
                for last 90 days
              </div>
            </div>
          )}
        </div>
      </section>
      { noTransactions && (
        <div className="dashboard_transactions transactions -empty">
          <div className="transactions_body">
            <div className="splash color-light-gray">
              <Svg name="reload-usd" className="splash_icon" />
              <div
                className="js-dashboard-no-transactions-text splash_message font-size-secondary-responsive"
              >
                No transactions to show<br/>
                for last 90 days <br/><br/>
                <a href="/transactions">All Transactions</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

SmartTransactionsList.propTypes = {
  transaction: types.TransactionShape,
  transactionShown: types.transactionReducerTypes.transactionShown,
  transactionsCompleted: types.transactionReducerTypes.transactionsCompleted,
  transactionsPending: types.transactionReducerTypes.transactionsPending,
  getPendingTransactions: PropTypes.func,
  getNextCompletedTransactions: PropTypes.func,
  getCompletedTransactions: PropTypes.func,
  setField: PropTypes.func,
  getTransactionDetails: PropTypes.func,
  filterByAccount: PropTypes.string,
  hasMore: PropTypes.bool
};

const mapStateToProps = (state) => {
  const { transactionsPending, transactionsCompleted, transaction, transactionShown  } = state.transactions;
  const getHasMore = () => {
    if(!!transactionsCompleted) {
      const paging = transactionsCompleted.paging || {};
      return (paging.offset + TRANSACTIONS_CONST.COMPLETED_PAGE_LIMIT) < paging.count;
    }
  };

  return {
    transactionsPending,
    transactionsCompleted,
    transaction,
    transactionShown,
    hasMore: getHasMore()
  };
};

export default connect(mapStateToProps, {
  getPendingTransactions,
  getNextCompletedTransactions,
  getCompletedTransactions,
  setField,
  getTransactionDetails,
})(SmartTransactionsList);