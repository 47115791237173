import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BasicLayout from '../basic-layout';
import { OnMobile, OnDesktop } from '../../components/breakpoints';
import Button from '../../components/button';
import FormInput from '../../components/form-input';
import FormTextArea from '../../components/form-textarea';
import CurrencyInput from '../../components/currency-input';
import Svg from '../../components/svg';
import { setField, createInvoice, setValidationError, closeInvoiceForm } from '../../actions/invoice-actions';

const InvoiceForm = ({
  isLoading,
  createInvoice,
  newInvoice,
  setField,
  validationErrors,
  setValidationError,
  closeInvoiceForm,
  contactType
}) => {
  const {
    orderId,
    amount,
    tax,
    description,
    send_to_email,
    send_to_sms,
    expires,
    attributes,
    total,
    recipient,
  } = newInvoice;

  const setContactType = (type) => {
    setField('contactType', type);
  };

  const handleChange = (field, value) => {
    setField(['newInvoice', field], value);
  };

  const handleAttributeChange = (index, field, value) => {
    const updatedAttributes = [...attributes];
    updatedAttributes[index][field] = value;
    handleChange('attributes', updatedAttributes);
  };

  const addAttributes = () => {
    setField(['newInvoice', 'attributes'], [...attributes, { name: '', value: '' }]);
  };

  const removeAttribute = (index) => {
    const updatedAttributes = [...attributes];
    updatedAttributes.splice(index, 1);
    setValidationError(`attributes.${index}`, '');
    handleChange('attributes', updatedAttributes);
  };

  const SEND_TO_TEXT = 'Send to ';

  return (
    <BasicLayout wrapperClassName="invoice-form -close-to-header">
      <div className="page_header -with-sub">
        <h1 className="js-page-title page_title">Create a new Invoice</h1>
        <p className="page_subtitle color-secondary">
          Send an invoice to prompt for payment for bills or services. 
          If an email or phone number are provided, the recipient will receive an email or text message with a link to pay;
           otherwise, you can send the invoice link generated for payment.
        </p>
      </div>
      <OnDesktop>
        <div className="layer -row  full-width invoice-form__subtitle">
          <span>
            Send invoice to
          </span>
          <a className="invoice-form__share-to" onClick={() => {
            setContactType(contactType === 'email' ? 'phone' : 'email');
          }}>
            {SEND_TO_TEXT} {contactType === 'email' ? 'phone' : 'email'}
          </a>
        </div>
      </OnDesktop>
      <div className="layer full-width">
        {contactType === 'email' ? (
          <FormInput
            color="white"
            inputName="email"
            label="Email"
            onChange={value => {
              setValidationError('send_to_email', '');
              handleChange('send_to_email', value);
            }}
            value={send_to_email}
            invalid={!!validationErrors.send_to_email}
            errorMessage={validationErrors.send_to_email}
          />
        ) : (
          <FormInput
            color="white"
            inputName="phone"
            label="Phone"
            type="tel"
            onChange={value => {
              setValidationError('send_to_sms', '');
              handleChange('send_to_sms', value.replace(/[-()+\s_]/gi, ''));
            }}
            value={send_to_sms}
            mask={process.env.REACT_APP_APPLICATION_ENV !== 'development' ? '(999) 999-9999' : '+9 (999) 999-9999'}
            invalid={!!validationErrors.send_to_sms}
            errorMessage={validationErrors.send_to_sms}
          />
        )}
      </div>
      <OnMobile>
        <a className="invoice-form__share-to" onClick={() => {
          setContactType(contactType === 'email' ? 'phone' : 'email');
        }}>
          {SEND_TO_TEXT} {contactType === 'email' ? 'phone' : 'email'}
        </a>  
      </OnMobile>

      <div className="layer full-width">
        <FormInput
          color="white"
          inputName="recipient-name"
          label="Recipient name"
          onChange={value => handleChange('recipient', value)}
          value={recipient}
          maxLength={50}
          invalid={!!validationErrors.recipient}
          errorMessage={validationErrors.recipient}
        />
      </div>

      <p className="invoice-form__subtitle">
        Invoice Details
      </p>
      <div className="page_body invoice-form__body">
        <div className="page_content">
          <form className="form" action="">
            <div className="layer full-width">
              <FormInput
                color="white"
                inputName="order-id"
                label="Order or Reference ID (required)"
                onChange={value => {
                  setValidationError('orderId', '');
                  handleChange('orderId', value);
                }}
                value={orderId}
                required
                invalid={!!validationErrors.orderId}
                errorMessage={validationErrors.orderId}
                maxLength={255}
              />
            </div>

            <div className="layer full-width">
              <FormTextArea
                color="white"
                inputName="description"
                label="Description (required)"
                onChange={value => {
                  setValidationError('description', '');
                  handleChange('description', value);
                }}
                value={description}
                className="invoice-form__description"
                invalid={!!validationErrors.description}
                errorMessage={validationErrors.description}
                maxLength={255}
              />
            </div>
            
            <div className="layer -col -gap-5 full-width">
              <CurrencyInput
                color="white"
                inputName="amount"
                label="Amount (required)"
                onChange={value => {
                  setValidationError('amount', '');
                  handleChange('amount', value);
                }}
                value={amount}
                required
                invalid={!!validationErrors.amount}
                errorMessage={validationErrors.amount}
              />
              <CurrencyInput
                color="white"
                inputName="tax"
                label="Tax"
                onChange={value => handleChange('tax', value)}
                value={tax}
              />
              <CurrencyInput
                color="white"
                inputName="total"
                label="Total"
                onChange={value => handleChange('total', value)}
                value={total}
              />
            </div>
            
            <div className="layer full-width">
              <FormInput
                color="white"
                type="number"
                inputName="expiration-date"
                label="Days before expiration"
                onChange={value => {
                  if(value.length < 4) {
                    const digitsOnly = value.replace(/\D/g, '');
                    const noLeadingZero = digitsOnly.replace(/^0+/, '');
                    handleChange('expires', noLeadingZero);
                  }
                }}
                value={expires}
              />
            </div>
            
            <p className="invoice-form__subtitle">
              Additional info
            </p>
            {Array.isArray(attributes) && attributes.length > 0 && (
              <div className="layer full-width attributes-container">
                {attributes.map((attribute, index) => (
                  <div className="attribute-fields" key={`attribute-${index}`}>
                    <FormInput
                      color="white"
                      inputName="attribute-name"
                      label="Label"
                      onChange={value => handleAttributeChange(index, 'name', value)}
                      value={attribute.name}
                      errorMessage={validationErrors[`attributes.${index}`]}
                      error={!!validationErrors[`attributes.${index}`]}
                      maxLength={255}
                    />
                    <FormInput
                      color="white"
                      inputName="attribute-value"
                      label="Value"
                      onChange={value => handleAttributeChange(index, 'value', value)}
                      value={attribute.value}
                      maxLength={255}
                    />
                    <Button
                      color="transparent"
                      xSize="xs"
                      className="remove-attribute attributes-button"
                      onClick={() => removeAttribute(index)}
                    >
                      <Svg name="cross" className="remove-attribute-icon attributes-button-icon" />
                    </Button>
                  </div>
                ))}
              </div>
            )}
            <Button
              color="transparent"
              xSize="full"
              className="add-attributes attributes-button"
              onClick={addAttributes}
              ySize="xs"
            >
              <Svg name="plus" className="add-attributes-icon attributes-button-icon" />
                Add field
            </Button>
          </form>
        </div>
      </div>
      <div className="page_controls -align-top-desktop">
        <Button
          xSize="full"
          transparency="full"
          className="js-cancel-button page_control -cancel"
          onClick={() => closeInvoiceForm()}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button page_control -submit"
          onClick={createInvoice}
          disabled={
            isLoading
          }
          loading={isLoading}
        >
          {newInvoice.send_to_email || newInvoice.send_to_sms ? 'Send Invoice' : 'Create Invoice'}
        </Button>
      </div>
    </BasicLayout>
  );
};

InvoiceForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  createInvoice: PropTypes.func.isRequired, 
  setField: PropTypes.func.isRequired,
  closeInvoiceForm: PropTypes.func.isRequired,
  contactType: PropTypes.string.isRequired,
  validationErrors: PropTypes.shape({
    send_to_email: PropTypes.string,
    send_to_sms: PropTypes.string,
    orderId: PropTypes.string,
    amount: PropTypes.string,
    description: PropTypes.string,
    recipient: PropTypes.string,
  }),
  newInvoice: PropTypes.shape({
    orderId: PropTypes.string,
    recipient: PropTypes.string,
    amount: PropTypes.string,
    tax: PropTypes.string,
    total: PropTypes.string,
    description: PropTypes.string,
    expires: PropTypes.string,
    attributes: PropTypes.arrayOf(PropTypes.shape({})),
    send_to_email: PropTypes.string,
    send_to_sms: PropTypes.string,
  }).isRequired,
  setValidationError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ...state.invoice,
});

export default connect(mapStateToProps, { setField, createInvoice, setValidationError, closeInvoiceForm })(InvoiceForm);
