import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Svg from '../svg';

const MenuHidden = ({ userName, logoutUser }) => {
  return (
    <Fragment>
      <nav className="header-menu_body -primary">
        <span className="header-menu_item -user-name">
          <Svg name="avatar-stub" className="header-menu_item-icon -no-stroke" />
          {userName}
        </span>
        <a
          href={process.env.REACT_APP_HELP_DESK_URL}
          className="header-menu_item -help-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Svg name="help-desk" className="header-menu_item-icon" />
            Customer Support
        </a>
      </nav>
      <nav className="header-menu_body -secondary -right">
        <button className="header-menu_logout" onClick={logoutUser}>
          {userName}
          <Svg name="logout" />
        </button>
        <div className="header-menu_body-content">
          <a href={process.env.REACT_APP_HELP_DESK_URL} className="header-menu_item -sub">
            Customer Support
          </a>
          <a href="#logout" className="header-menu_item -sub" onClick={logoutUser}>
            Log Out
          </a>
        </div>
      </nav>
    </Fragment>
  );
};

MenuHidden.propTypes = {
  userName: PropTypes.string,
  logoutUser: PropTypes.func,
};

export default MenuHidden;