import moment from 'moment';
import { select } from 'redux-saga/effects';
import { takeLatest, put, call } from 'redux-saga/effects';
import { find, propEq, path, clone } from 'ramda';
import { setField, setValidationError } from '../actions/invoice-actions';
import { addNotification } from '../actions/toast-actions';
import VALIDATION from '../utils/validation';
import { CONSTANTS } from '../constants/invoice-constants';
import REQUESTS from '../utils/requests';
import { mapInvoiceToTransaction } from '../utils/invoice-utils';
import { TIMEFRAME_INTERVALS } from '../constants/transactions-constants';
import { rejectEmptyParams } from '../utils/transaction-utils';
import { INIT_STATE, INVOICE_INITIAL_STATE } from '../reducers/invoice-reducer';
import { history } from '../init-store';
import { getFieldsFromAttributes, removeFieldsFromInvoice } from '../utils/invoice-utils';

function* getCompletedInvoices() {
  try {
    yield put(setField('isLoading', true));
    const { wallet: { wallet_id } } = yield select(state => state.wallet);
    
    if(wallet_id) {
      const userFilters = yield select(path(['invoice', 'filters']));
      const user = yield select(path(['user', 'user']));

      if(userFilters.status.length > 0 && userFilters.status.length === 1 && userFilters.status.includes(CONSTANTS.INVOICE_STATUS.pending)) {
        yield put(setField('completedInvoiceList', []));
        yield put(setField('completedInvoiceTransactions', []));
      } else {
        if (userFilters.timeFrameValue === TIMEFRAME_INTERVALS.ANY) {
          userFilters.timeFrameDates.from_date = moment(user.created_at).subtract(1, 'd').toISOString();
          userFilters.timeFrameDates.to_date = moment().add(1, 'd').toISOString();
        }
        
        const filterStatus = userFilters.status.length > 0 ? userFilters.status.filter((status) => status !== CONSTANTS.INVOICE_STATUS.pending) : [
          CONSTANTS.INVOICE_STATUS.completed,
          CONSTANTS.INVOICE_STATUS.expired,
          CONSTANTS.INVOICE_STATUS.canceled,
        ];

        const { success, data } = yield REQUESTS.GET_INVOICE_LIST(wallet_id, rejectEmptyParams({
          send_to: userFilters.search,
          direction: 'outgoing',
          status: filterStatus.join(','),
          from_date: userFilters.timeFrameDates.from_date,
          to_date: userFilters.timeFrameDates.to_date,
          limit: 100,
        }));
  
        if (success) {
          
          try { 
            yield put(setField('completedInvoiceList', data));
            yield put(setField('completedInvoiceTransactions', data.map(mapInvoiceToTransaction)));
          } catch (error) {
            console.log(error);
          }
          yield put(setField('isLoading', false));
        }
      }
      yield put(setField('isLoading', false));

    } else {
      yield put(setField('completedInvoiceList', []));
      yield put(setField('completedInvoiceTransactions', []));
      yield put(setField('isLoading', false));
    }
  } catch (error) {
    yield put(setField('completedInvoiceList', []));
    yield put(setField('completedInvoiceTransactions', []));
    yield put(setField('isLoading', false));
    yield put(addNotification('Something went wrong', 400));
  }
 
}

function* getPendingInvoices() {
  try {
    yield put(setField('isLoading', true));
    const { wallet: { wallet_id } } = yield select(state => state.wallet);
    
    if(wallet_id) {
      const userFilters = yield select(path(['invoice', 'filters']));
      const user = yield select(path(['user', 'user']));

      if(userFilters.status.length > 0 && !userFilters.status.includes(CONSTANTS.INVOICE_STATUS.pending)) {
        yield put(setField('pendingInvoiceList', []));
        yield put(setField('pendingInvoiceTransactions', []));
      } else {
        if (userFilters.timeFrameValue === TIMEFRAME_INTERVALS.ANY) {
          userFilters.timeFrameDates.from_date = moment(user.created_at).subtract(1, 'd').toISOString();
          userFilters.timeFrameDates.to_date = moment().add(1, 'd').toISOString();
        }

        const filterStatus = 'pending';

        const { success, data } = yield REQUESTS.GET_INVOICE_LIST(wallet_id, rejectEmptyParams({
          send_to: userFilters.search,
          direction: 'outgoing',
          status: filterStatus,
          from_date: userFilters.timeFrameDates.from_date,
          to_date: userFilters.timeFrameDates.to_date,
          limit: 100,
        }));
  
        if (success) {
          
          try { 
            yield put(setField('pendingInvoiceList', data));
            yield put(setField('pendingInvoiceTransactions', data.map(mapInvoiceToTransaction)));
          } catch (error) {
            console.log(error);
          }
          yield put(setField('isLoading', false));
        }
      }
     
      yield put(setField('isLoading', false));
    } else {
      yield put(setField('pendingInvoiceList', []));
      yield put(setField('pendingInvoiceTransactions', []));
      yield put(setField('isLoading', false));
    }
  } catch (error) {
    yield put(setField('pendingInvoiceList', []));
    yield put(setField('pendingInvoiceTransactions', []));
    yield put(setField('isLoading', false));
    yield put(addNotification('Something went wrong', 400));
  }
 
}


function* getInvoiceDetails(action) {
  try {
    const { success, data } = yield REQUESTS.GET_INVOICE_DETAILS(action.payload.invoiceId);
    const { invoiceToShow } = yield select(state => state.invoice);

    if (success) {
      yield put(setField('invoiceToShow', Object.assign(invoiceToShow, data[0])));
    }
  } catch (error) {
    yield put(setField('invoiceDetails', {}));
    yield put(addNotification('Something went wrong', 400));
  }
}

function* createInvoice() {
  try {
    yield put(setField('isLoading', true));
    const { newInvoice, contactType } = yield select(state => state.invoice);
    const { wallet: { wallet_id }, accounts } = yield select(state => state.wallet);
    const emailValidation = VALIDATION.validateEmail(newInvoice.send_to_email);
    const phoneValidation = VALIDATION.validatePhoneNumber(newInvoice.send_to_sms);
    const orderIdValidation = VALIDATION.validateOrderId(newInvoice.orderId);
    const amountValidation = VALIDATION.validateAmount(newInvoice.amount);
    const validateInvoiceAttributes = VALIDATION.validateInvoiceAttributes(newInvoice.attributes);
    const validateDescription = VALIDATION.validateDescription(newInvoice.description);
    const validateName = VALIDATION.validateNoLeadingDash(newInvoice.recipient);

    const defaultAccount = find(propEq('isDefault', true))(accounts);
    const defaultAccountCurrency = defaultAccount && defaultAccount.balance && defaultAccount.balance.currency;
    const contactIsValid = (emailValidation.success || phoneValidation.success) || (!newInvoice.send_to_email && !newInvoice.send_to_sms);

    if(newInvoice.send_to_email && newInvoice.send_to_sms) {
      if(contactType === 'email') {
        delete newInvoice.send_to_sms;
      } else {
        delete newInvoice.send_to_email;
      }
    }

    if (
      contactIsValid && orderIdValidation.success && amountValidation.success
      && validateInvoiceAttributes.success && validateDescription.success && validateName.success
    ) {
      const { success } = yield REQUESTS.CREATE_INVOICE({
        ...newInvoice,
        // source_wallet_id: wallet_id,
        dest_wallet_id: wallet_id,
        expires: `${newInvoice.expires}d`,
        amount: {
          ordinal: newInvoice.amount.split('.')[0],
          decimal: newInvoice.amount.split('.')[1] || '00',
          currency: defaultAccountCurrency,
        },
        order_token: newInvoice.orderId,
        attributes: [
          {
            name: 'order_id',
            value: newInvoice.orderId,
          }, 
          {
            name: 'tax',
            value: newInvoice.tax,
          },
          {
            name: 'total',
            value: newInvoice.total,
          },
          ...newInvoice.attributes,
        ].filter((item) => item.value && item.name),
      });

      if (success) {
        yield put(setField('newInvoice', INIT_STATE.newInvoice));
        yield put(addNotification('Invoice created successfully', 200));
        history.push(`/invoices`);
      }

      yield put(setField('isLoading', false));
    } else {
      if(newInvoice.send_to_email) {
        yield put(setValidationError('send_to_email', emailValidation.message));
      }
      if(newInvoice.send_to_sms) {
        yield put(setValidationError('send_to_sms', phoneValidation.message));
      }

      if(Array.isArray(newInvoice.attributes)) {
        yield put(setValidationError(`attributes.${validateInvoiceAttributes.index}`, validateInvoiceAttributes.message));
      }

      yield put(setValidationError('recipient', validateName.message));
      yield put(setValidationError('description', validateDescription.message));
      yield put(setValidationError('orderId', orderIdValidation.message));
      yield put(setValidationError('amount', amountValidation.message));
      yield put(setField('isLoading', false));
    }
  } catch (error) {
    yield put(setField('isLoading', false));
    console.log(error);
    yield put(addNotification('Something went wrong', 400));
  }
}


function* applyFilters({ payload: { filters } }) {
  yield put(setField('filters', filters));
  yield call(getCompletedInvoices);
  yield call(getPendingInvoices);
}

function* deleteInvoice({ payload: { invoiceId } }) {
  try {
    const { success } = yield REQUESTS.DELETE_INVOICE(invoiceId);
    if (success) {
      yield put(addNotification('Invoice cancelled successfully', 200));
      yield call(getCompletedInvoices);
      yield call(getPendingInvoices);
      yield put(setField('invoiceToShow', {}));
      yield put(setField('invoiceShown', false));
      history.push(`/invoices`);
    }
  } catch (error) {
    yield put(addNotification('Something went wrong', 400));
  }
}

function* createNewInvoice({ payload: { invoice } }) {
  const mappedInvoice = yield call(getFieldsFromAttributes, invoice);
  const cleanedInvoice = yield call(removeFieldsFromInvoice, mappedInvoice);
  yield put(setField('contactType', cleanedInvoice.send_to_sms ? 'phone' : 'email'));
  yield put(setField('newInvoice',  cleanedInvoice));
  yield call(history.push, `/invoices/create`);
}

function* cancelInvoice({ payload: { invoiceId } }) {
  yield put(setField('isLoading', true));
  try {
    const { success } = yield REQUESTS.UPDATE_INVOICE(invoiceId, {
      status: CONSTANTS.INVOICE_STATUS.canceled,
    });
    if (success) {
      yield call(getCompletedInvoices);
      yield call(getPendingInvoices);
      yield put(setField('isLoading', true));
      yield put(setField('invoiceShown', false));
      yield put(setField('invoiceToShow', {}));
      yield put(addNotification('Invoice canceled', 200));

      history.push(`/invoices`);
    }
    yield put(setField('isLoading', false));
  } catch (error) {
    yield put(setField('isLoading', false));
    yield put(addNotification('Something went wrong', 400));
  }
}


function* clearInvoiceForm() {
  yield put(setField('newInvoice', clone(INVOICE_INITIAL_STATE)));
  yield put(setField('contactType', 'email'));
}

function* closeInvoiceForm() {
  yield call(clearInvoiceForm);
  yield call(history.push, `/invoices`);
}

function* openInvoiceForm() {
  yield call(clearInvoiceForm);
  yield call(history.push, `/invoices/create`);
}

function* invoiceSaga() {
  yield takeLatest(CONSTANTS.GET_INVOICE_DETAILS, getInvoiceDetails);
  yield takeLatest(CONSTANTS.CREATE_INVOICE, createInvoice);
  yield takeLatest(CONSTANTS.APPLY_FILTERS, applyFilters);
  yield takeLatest(CONSTANTS.DELETE_INVOICE, deleteInvoice);
  yield takeLatest(CONSTANTS.CREATE_NEW_INVOICE, createNewInvoice);
  yield takeLatest(CONSTANTS.CANCEL_INVOICE, cancelInvoice);
  yield takeLatest(CONSTANTS.GET_COMPLETED_INVOICES, getCompletedInvoices);
  yield takeLatest(CONSTANTS.GET_PENDING_INVOICES, getPendingInvoices);
  yield takeLatest(CONSTANTS.CLOSE_INVOICE_FORM, closeInvoiceForm);
  yield takeLatest(CONSTANTS.CLEAR_INVOICE_FORM, clearInvoiceForm);
  yield takeLatest(CONSTANTS.OPEN_INVOICE_FORM, openInvoiceForm);
}

export default [invoiceSaga];
