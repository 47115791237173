import { GET, POST, PUT, DELETE, CLIENT_ID, CLIENT_SECRET } from './api';

const REQUESTS = {
  GET_WALLET_BY_SEARCH_PARAM: search => GET('/v1/wallets', { search })(),
  GET_WALLET_BY_HANDLE: handle => GET('/v1/wallets', { handle })(),
  GET_USER_WALLETS: userId => GET(`/v1/users/${userId}/wallets`)(),
  GET_USER_DETAILS: userId => GET(`/v1/users/${userId}`)(),
  UPDATE_USER_ADDRESS: (userId, address) => PUT(`/v1/users/${userId}`, address)(),
  GET_WALLET_BANK_ACCOUNTS: walletId => GET(`/v1/wallets/${walletId}/bank_accounts`)(),
  GET_WALLET_ACCOUNTS: walletId => GET(`/v1/wallets/${walletId}/accounts`)(),
  CREATE_USER: user => POST('/v1/users', user)(),
  RECOVER_PASSWORD: login => POST('/v1/auth/forgot_password', { login })(),
  RESET_PASSWORD: ({ token, password }) => POST('/v1/auth/reset_password', { token, password })(),
  ADD_PHONE: ({ userId, phone }) => POST(`/v1/users/${userId}/phones`, { phone })(),
  UPDATE_EMAIL: ({ userId, email }) => POST(`/v1/users/${userId}/emails`, { email })(),
  VERIFY_NUMBER_SSN: ({ userId, ssn }) => POST(`/v1/users/${userId}/verify`, { last4: ssn })(),
  UPDATE_USER: (userId, user) => PUT(`/v1/users/${userId}`, user)(),
  SEND_DOCUMENTATION_EMAIL: (userId) => 
    POST(`/v1/users/${userId}/emails/send/additional_verification_required`)(),
  GET_USER_NOTICES: (userId) => GET(`/v1/users/${userId}/notices`)(),
  UPDATE_USER_NOTICE: ({ userId, noticeName }) => POST(`/v1/users/${userId}/notices/${noticeName}`)(),

  UPDATE_WALLET: (walletId, wallet) => PUT(`/v1/wallets/${walletId}`, wallet)(),
  UPDATE_WALLET_HANDLE: (walletId, handle) => PUT(`/v1/wallets/${walletId}`, handle)(),
  UPDATE_WALLET_EIN: (walletId, ein) => PUT(`/v1/wallets/${walletId}`, { ein })(),
  UPDATE_TRANSACTION_ALLOCATION: (walletId, walletAccounts) => PUT(`/v1/wallets/${walletId}/accounts`, walletAccounts)(),
  GET_RATE: () => GET('/v1/rates')(),
  MAKE_PAYMENT: payment => POST('/v1/payments', payment)(),
  MAKE_ORDER_PAYMENT: payment => POST('/v1/orders', payment)(),
  GET_ORDER_PREFLIGHT: payment => POST('/v1/orders/preflight', payment)(),

  SAVE_RECURRENT_PAYMENT: payment => POST('/v1/agreements', payment)(),
  MAKE_RECURRENT_PREFLIGHT: payment => POST('/v1/agreements/preflight', payment)(),
  GET_TRANSACTIONS: (walletId, filters) => GET(`/v1/wallets/${walletId}/transactions`, filters)(),
  GET_TRANSACTION_DETAILS: (walletId, transactionId) =>
    GET(`/v1/wallets/${walletId}/transactions/${transactionId}/details`)(),

  REPORT: (id, reason) => POST(`/v1/transactions/abuse`, { object_id: id, reason: reason })(),
  MAKE_REFUND: (transactionId, payment) => PUT(`/v1/transactions/${transactionId}/refund`, payment)(),
  GET_REFUND: (transactionId, payment) => GET(`/v1/transactions/${transactionId}/refund`, payment)(),
  // Transfer
  MAKE_TRANSFER: (walletId, transfer) => POST(`/v1/wallets/${walletId}/transfers`, transfer)(),
  GET_USER_REWARDS: userId => GET(`/v1/users/${userId}/rewards`)(),
  GET_USER_REWARDS_ACTIVITY: userId => GET(`/v1/users/${userId}/rewards/activity`)(),
  // Bank Accounts
  ADD_BANK_ACCOUNT: (walletId, bankAccount) =>
    POST(`/v1/wallets/${walletId}/bank_accounts`, bankAccount)(),

  VERIFY_BANK_ACCOUNT: (walletId, bankAccountId, amount) =>
    POST(`/v1/wallets/${walletId}/bank_accounts/${bankAccountId}/verify`, amount)(),

  GET_BANK_ACCOUNT: (walletId, bankAccountId) =>
    GET(`/v1/wallets/${walletId}/bank_accounts/${bankAccountId}`)(),

  UPDATE_BANK_ACCOUNT: (walletId, bankAccountId, bankAccountLabel) =>
    PUT(`/v1/wallets/${walletId}/bank_accounts/${bankAccountId}`, bankAccountLabel)(),

  REMOVE_BANK_ACCOUNT: (walletId, bankAccountId) =>
    DELETE(`/v1/wallets/${walletId}/bank_accounts/${bankAccountId}`)(),

  OBTAIN_BANK_TOKEN: (userId) =>
    POST(`/v1/users/${userId}/banking`)(),


  LOGOUT: () => POST('/v1/auth/logout')(),

  // partner signup
  GET_PARTNER_USER: (partnerCode, token) => GET(`/v1/partners/${partnerCode}/users/${token}`)(),
  POST_PARTNER_USER: (partnerCode, token, gbPassword) =>
    POST(`/v1/partners/${partnerCode}/users/${token}/credentials`, {
      password: gbPassword
    })(),

  SIGNUP_FOR_BITRAIL: (partnerCode, token, password, consent, handle) =>
    POST(`/v1/partners/${partnerCode}/users/${token}/signup`, {
      password,
      consent,
      handle,
    })(),

  // Loan
  GET_LOAN_OFFER: userId => GET(`/v1/users/${userId}/credit`)(),
  CHECK_LOAN_OFFER: (userId, amount) => PUT(`/v1/users/${userId}/credit`, { amount })(),
  ACCEPT_LOAN_OFFER: (userId, rateId) => POST(`/v1/users/${userId}/credit`, { rate_id: rateId })(),

  // Auth
  SOFT_LINK_AUTH: mobile => POST(`/v1/auth/link${mobile ? `?mobile=${mobile}` : ''}`)(),
  CHECK_SOFT_LINK: (mobile, avi) => GET(`/v1/auth/info${mobile ? `?mobile=${mobile}` : ''}${avi ? `?avi=${avi}` : ''}`)(),
  GET_VERIFICATION_SERVICES: (userId, forceList) => GET(`/v1/users/${userId}/verify?${forceList ? 'forcelist=1' : ''}`)(),
  GET_VERIFICATION_SERVICE_BY_ID: (userId, serviceId) => GET(`/v1/users/${userId}/verify/${serviceId}`)(),
  GET_KYC_SESSION_STATUS: (userId, serviceId, sessionId) => GET(`/v1/users/${userId}/verify/${serviceId}/session/${sessionId}`)(),
  CONSENT_AGREEMENT: (userId, consentName) => POST(`/v1/users/${userId}/consents/${consentName}`)(),
  RESEND_VERIFICATION_EMAIL: userId => POST(`/v1/users/${userId}/resend`)(),
  RESEND_OTP: avi => POST(`/v1/auth/link/resend`, { avi })(),
  FINISH_TFA_OTP: otp => POST(`/v1/auth/tfafinish/otp`, { otp })(),

  OBTAIN_APP_TOKEN: () =>
    POST('/v1/auth/token', {
      scope: 'everything',
      grant_type: 'client_credentials',
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    })(),

  OBTAIN_APP_TOKEN_OAUTH: (avi) =>
    POST('/v1/auth/token', {
      scope: 'everything',
      grant_type: 'client_credentials',
      avi
    })(),
    
  OBTAIN_APP_CONFIG: () => GET(`/v1/clients/${CLIENT_ID}/config`)(),
  OBTAIN_APP_OPTIONS: (clientui) => GET(`/v1/clients/${CLIENT_ID}/options`, { pg: window.location.pathname }, clientui)(),

  OBTAIN_TOKEN: credentials =>
    POST('/v1/auth/token', {
      ...credentials,
      scope: 'everything',
      grant_type: 'password',
      client_id: CLIENT_ID,
      client_secret:CLIENT_SECRET
    })(),

  OBTAIN_TOKEN_OAUTH: credentials =>
    POST('/v1/auth/authorize', {
      ...credentials,
    })(),

  OAUTH_AUTHORIZE: (queryParams) => GET('/v1/auth/authorize', queryParams)(),

  CREATE_USER_OAUTH: (credentials) => GET('/v1/auth/authorize', { 
    'login-email': credentials.email,
    'login': credentials.email,
    'login-password': credentials.password,
    'avi': credentials.avi,
    ...credentials
  })(),

  GET_OAUTH_TOKEN: (queryParams) => GET('/v1/auth/token', queryParams)(),

  GET_CONSENT: (consent) => GET(`/v1/auth/consents/${consent}`)(),

  GET_OAUTH_INFO: (avi) => GET(`/v1/auth/info?avi=${avi}`)(),

  GET_AVI_STATUS: (avi) => GET(`/v1/auth/info/${avi}`)(),

  // GB
  GET_GB_USER: userId => GET(`/v1/users/${userId}/gun_broker`)(),
  LOGIN_GB_USER: (userId, gbValidationId, username, password, walletId) =>
    POST(`/v1/users/${userId}/gun_broker/${gbValidationId}`, {
      username, password, wallet_id: walletId
    })(),
  SET_GB_USER: ({ userId, status, walletId, gbValidationId }) =>
    PUT(`/v1/users/${userId}/gun_broker/${gbValidationId}`, {
      status, // attempted, confirmed, declined
      wallet_id: walletId,
    })(),
  
  SIGNUP_FROM_GB: ({ token, password, consent, userId }) => POST('/v1/auth/streamlined_setup', {
    token,
    password,
    consent,
    userId,
  })(),

  GET_CURRENCY_PROMOS: (walletId) => GET(`/v1/wallets/${walletId}/promotions`)(),

  // Card Accounts

  GET_WALLET_CARD_ACCOUNTS: walletId => GET(`/v1/wallets/${walletId}/cards`)(),

  GET_CARD_SERVICE: (userId) =>
    POST(`/v1/users/${userId}/cardservice`)(),

  ADD_CARD_ACCOUNT: (walletId, cardAccount) =>
    POST(`/v1/wallets/${walletId}/cards`, cardAccount)(),

  VERIFY_CARD_ACCOUNT: (walletId, cardAccountId, amount) =>
    POST(`/v1/wallets/${walletId}/cards/${cardAccountId}/verify`, amount)(),

  GET_CARD_ACCOUNT_DETAILS: (walletId, cardAccountId) =>
    GET(`/v1/wallets/${walletId}/cards/${cardAccountId}`)(),

  UPDATE_CARD_ACCOUNT: (walletId, cardAccountId, cardAccountLabel) =>
    PUT(`/v1/wallets/${walletId}/cards/${cardAccountId}`, cardAccountLabel)(),

  REMOVE_CARD_ACCOUNT: (walletId, cardAccountId) =>
    DELETE(`/v1/wallets/${walletId}/cards/${cardAccountId}`)(),

  CREATE_INVOICE: (invoice) => POST('/v1/invoices', invoice)(),
  GET_INVOICE_LIST: (walletId, params) => GET(`/v1/wallets/${walletId}/invoices`, params)(),
  GET_INVOICE_DETAILS: (invoiceId) => GET(`/v1/invoices/${invoiceId}`)(),
  DELETE_INVOICE: (invoiceId) => DELETE(`/v1/invoices/${invoiceId}`)(),
  /**
   * Updates an invoice with the given parameters.
   * @param {string} params.status - One of 'pending' or 'completed' or 'canceled'.
   * @param {string|Date} params.expires - A date or duration. Succeeds only if status is 'pending' or 'expired'. 
   * If the date is in the past, the status is 'expired'. If it is in the future, the status is changed to 'pending'.
   * @param {string} params.recipient - Changes recipient name.
   * @param {string} params.url - Callback URL on completion.
   * @returns {Promise} - A promise that resolves when the invoice is updated.
 */
  UPDATE_INVOICE: (invoiceId, params) => PUT(`/v1/invoices/${invoiceId}`, params)(),
};

export default REQUESTS;
