import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as types from '../../constants/prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { find, propEq, path, reduce, filter, max } from 'ramda';
import Button from '../../components/button';
import { history } from '../../init-store';
import CONST from '../../constants/sign-up-constants';
import { KYC_STATUS } from '../../constants/user-constants';
import { setField as setSignUpField, startVerification } from '../../actions/sign-up-actions';
import { setField as setBankAccountField, addBankAccountMassive } from '../../actions/bank-account-actions';
import { sendVerificationEmail, sendDocumentationEmail } from '../../actions/dashboard-actions';
import { setField, updateUserSSN, updateUserNotice } from '../../actions/user-actions';
import { addBankAccountInstant } from '../../actions/bank-account-actions';
import { updateEIN } from '../../actions/wallet-actions';
import { STEP } from '../../reducers/user-reducer';
import ModalAccountOwnershipValidation from '../../components/bank-accounts/modal-account-ownership-validation';
import ModalLinkGB from '../../components/wallet-settings/modal-link-gb';
import YodleeModal from '../../components/yodlee-modal';
import IdentityVerificationModal from '../../components/identity-verification-modal';
import ModalSSN from '../../components/modal-ssn';
import ModalEIN from '../../components/modal-ein';
import ModalACH from '../../components/modal-ach';
import { hasLoanOffer } from '../../utils/permissions';
import { currencyFormat, balance } from '../../utils/send-money-utils';
import { hasNotices } from '../../utils/notices';
import ModalNotice from '../../components/modal-notice';
import { hasPermission, permissionErrorMessage } from '../../utils/permissions';
import { userBalanceSelector } from '../../reducers/wallet-reducer';
import NotificationBadge from '../../components/notification-badge';
import { Decimal } from 'decimal.js';
import { getStoreItem } from '../../utils/string-utils';
import { canAddBankAccountSelector } from '../../reducers/app-reducer';

const ACHNotificationText = () => {
  return (<div className="ach-notification-text">
    By adding a bank account, you agree with the <a className="info-link">ACH&nbsp;Authorization</a>
  </div>);
};

const DashboardNotifications = (props) => {
  const [accountOwnershipValidationModalOpened, setAccountOwnershipValidationModalOpened] = useState(false);
  const [gbModalShown, setGbModalShown] = useState(false);
  const [verificationModalShown, setVerificationModalShown] = useState(false);
  const [ssnModalShown, setSSNModalShown] = useState(false);
  const [einModalShown, setEINModalShown] = useState(false);
  const [achModalShown, setACHModalShown] = useState(false);

  const initState = () => {
    setAccountOwnershipValidationModalOpened(false);
    setGbModalShown(false);
    setVerificationModalShown(false);
  };

  useEffect(() => {
    return () => props.setField(['gbData', 'popupIsOpened'], false);
  }, []);

  useEffect(() => {
    initState();
  }, [props.bankAccounts]);

  const onNotificationClick = ({ target }) => {
    const { 
      redirectTo,
      signUpStep,
      bankAccount,
      sendEmail,
      linkGunBroker,
      showIdentityModal,
      yodlee,
      ssnModal,
      einModal
    } = props.notification;

    if (yodlee) {      
      if (target.className === 'info-link') {
        toggleAchModal();
      } else {
        props.addBankAccountInstant();
      }
      
    }

    if (sendEmail) {
      props.sendVerificationEmail();
    }

    if(showIdentityModal) {
      setVerificationModalShown(true);
    }

    if (signUpStep) {
      props.setSignUpField('step', CONST.STEP_VERIFICATION_SELECT);
      props.setSignUpField('existedUser', true);
      props.setSignUpField('userId', props.userId);
      props.startVerification();
    }

    if (redirectTo) {
      history.push(redirectTo);
    }

    if (bankAccount) {
      toggleAccountOwnershipValidationModal();
    }

    if (linkGunBroker) {
      props.setField(['gbData', 'step'], STEP.LINK);
      props.setField(['gbData', 'popupIsOpened'], true);
    }

    if(ssnModal) {
      toggleSSNModal();
    }

    if(einModal) {
      toggleEINModal();
    }
  };

  const closeLinkGBAccount = () => {
    props.setField(['gbData', 'step'], STEP.LINK);
    props.setField(['gbData', 'popupIsOpened'], false);
  };

  const toggleAccountOwnershipValidationModal = () => {
    setAccountOwnershipValidationModalOpened(!accountOwnershipValidationModalOpened);
  };

  const toggleAchModal = () => {
    setACHModalShown(!achModalShown);
  };

  const toggleSSNModal = () => {
    setSSNModalShown(!ssnModalShown);
  };

  const toggleEINModal = () => {
    setEINModalShown(!einModalShown);
  };

  const checkPermissions = () => {
    const { permissions, userBalance, transactionsPending = { data: [] } } = props;

    if (!hasPermission(permissions, 'resides_in_allowed_state')) {
      return {
        message: permissionErrorMessage(permissions, 'resides_in_allowed_state'),
        type: 'permission'
      };
    } else if(userBalance < 0) {
      const canDepositToBr = hasPermission(permissions, 'can_deposit_to_br');
      const onClick = canDepositToBr ? () => { history.push('/transfer'); } : null;
      const pendingAmount = reduce(
        (acc, value) => {
          acc = acc.plus(balance(value.net_amount.ordinal, value.net_amount.decimal, value.net_amount.sign));
    
          return acc;
        },
        new Decimal(0),
        filter(
          (transaction) => 
            path(['destination', 'wallet_id'], transaction) === getStoreItem('walletID') &&
            path(['transaction_amount', 'sign'], transaction.transaction_details.all.find((detail) => detail.account_type === 'account')) === CONST.PLUS,
          transactionsPending.data 
        )
      ).toFixed(2,3);

      if (Decimal(userBalance).plus(pendingAmount).greaterThanOrEqualTo(0)) {
        return {
          message: 'Your negative balance will be resolved once funds transfer is complete.',
          type: 'permission'
        };
      } else {
        return {
          message: `Add ${currencyFormat(Decimal(userBalance).abs())} to your account to continue using the system`,
          onClick,
          type: 'permission'
        };
      }
    }

    return null;
  };

  const [noticeModalShown, setNoticeModalShown] = useState(false);
  const [shownNotice, setShownNotice] = useState({ notice: { notice_text: '' } });

  const checkNotices = () => {
    const { notices } = props;
    const noticesToShow = hasNotices(notices);


    return noticesToShow ? { 
      data: noticesToShow[0],
      message: noticesToShow[0].notice.notice_summary,
      onClick: () => {
        setShownNotice(noticesToShow[0]);
        setNoticeModalShown(true);
      },
      type: 'notice',
    } : null;
  };

  const { permissions, notification, isLoading: dashboardIsLoading, userIsLoading, updateUserNotice } = props;
  const {
    setBankAccountField,
    addBankAccountMassive,
    bankAccountFlow: {
      isLoading,
      errorMessage,
      yodleeModalShown,
      isYodleeDisabled,
      yodleeUrl,
      yodleeJWT,
      yodleeServiceId,
      configName,
    },
  } = props;

  const onNoticeClose = (isAcknowledged) => {
    setNoticeModalShown(false);
    if(isAcknowledged) {
      updateUserNotice(shownNotice.notice.name);
    }
  };

  const notices = checkNotices();
  const notifications = [
    checkPermissions(),
    notification ? {
      onClick: onNotificationClick,
      message: notification.message,
      children: notification.children,
      type: 'action'
    } : null,
    notices
  ].filter((item) => !!item);

  const getModals = () => notification && (
    <Fragment>
      <IdentityVerificationModal
        isLoading={dashboardIsLoading}
        shown={verificationModalShown}
        onSubmitDocumentation={() => {
          // SEND_VERIFICATION_EMAIL
          props.sendDocumentationEmail();
        }}
        onShow={() => {
          setVerificationModalShown(true);
        }}
        onClose={() => {
          setVerificationModalShown(false);
        }}
        onPhoneNumberClick={() => {
          props.setSignUpField('step', CONST.STEP_PHONE_NUMBER);
          history.push('/signup');
        }}
      />

      <ModalACH 
        shown={achModalShown}
        onClose={toggleAchModal}
      />

      <YodleeModal
        shown={yodleeModalShown}
        bankAccountFlow={{
          yodleeUrl,
          yodleeJWT,
          yodleeServiceId,
          configName,
        }}
        isYodleeDisabled={isYodleeDisabled}
        setField={setField}
        addBankAccountMassive={ bankAccounts => addBankAccountMassive(bankAccounts) }
        onClose={() => setBankAccountField('yodleeModalShown', false)}
        isLoading={isLoading}
        closeButton
      />

      <ModalLinkGB
        show={props.gbPopupShown}
        containerClassName={cn({ '-top': gbModalShown })}
        onClose={closeLinkGBAccount}
      />

      {notification.bankAccount && (
        <ModalAccountOwnershipValidation
          bankAccount={notification.bankAccount}
          errorMessage={errorMessage}
          show={accountOwnershipValidationModalOpened}
          containerClassName={cn({ '-top': accountOwnershipValidationModalOpened })}
          onClose={toggleAccountOwnershipValidationModal}
          isLoading={isLoading}
        />
      )}

      {notification.ssnModal && (
        <ModalSSN 
          updateSSN={(ssn) => props.updateUserSSN(ssn)}
          closeButton
          isLoading={userIsLoading}
          show={ssnModalShown}
          onClose={toggleSSNModal}
        />
      )}

      {notification.einModal && (
        <ModalEIN
          updateEIN={(ein) => props.updateEIN(ein)}
          closeButton
          isLoading={userIsLoading}
          show={einModalShown}
          onClose={toggleEINModal}
        />
      )}
      {notices && (
        <ModalNotice
          notice={shownNotice}
          onNoticeClose={onNoticeClose}
          show={noticeModalShown}
          closeButton
        />
      )}
      
    </Fragment>
  );

  // TODO look for another approach here
  const getSliderStyles = () => {
    const ROW_SIZE = 17;
    const BASE_HEIGHT = 146;
    const BASE_ROWS = 4;
    const ROW_HEIGHT = 17;
    const getRows = (notification) => {
      const words = notification.message.split(' ');
      let rows = 0;
      words.reduce((result, word, i, arr) => {
        if(i > 0 && result === '') {
          if(arr[i-1].length + word.length <= ROW_SIZE) {
            return `${arr[i-1]} ${word}`;
          } else {
            rows++;
            return '';
          }
        } else {
          if(result.length + word.length <= ROW_SIZE) {
            return `${result} ${word}`;
          } else {
            rows++;
            return '';
          }
        }
      }, '');
      return rows;
    };
    let maxRows = max(...notifications.map(getRows));

    return maxRows > 4 
      ? { 
        container: { 'padding-bottom': `${BASE_HEIGHT + ROW_HEIGHT * (maxRows - BASE_ROWS)}px` },
        item: { height: `${BASE_HEIGHT + ROW_HEIGHT * (maxRows - BASE_ROWS)}px` } 
      }
      : { container: {}, item: {} };
  };

  const windowWidth = window.innerWidth;
  const NOTIFICATIONS_SIZES = {
    WIDTH: 146,
    MARGIN: 5,
    CONTAINER_PADDINGS: 40
  };
  const notificationsWidth = notifications.length * (
    NOTIFICATIONS_SIZES.WIDTH + (notifications.length - 1) * NOTIFICATIONS_SIZES.MARGIN
  ) + NOTIFICATIONS_SIZES.CONTAINER_PADDINGS;
  const isSlider = windowWidth < notificationsWidth;
  const sliderStyles = isSlider ? getSliderStyles() : { container: {}, item: {} };

  return (
    <div className={cn('dashboard_badges layer -space-down-lg', isSlider && '-slider')} style={sliderStyles.container}>
      { notifications && notifications.length > 0 && (
        <Fragment>
          <div className={cn(
            'layer notifications-container -space-up-xs-mobile -space-down-lg-mobile -space-up-sm-desktop -space-down-sm-desktop',
            `notifications-container__${notifications.length}`
          )} style={sliderStyles.container} >
            {notifications.map((item, index) => (
              <NotificationBadge key={`notification-${index}`} {...item} sliderStyles={sliderStyles.item} />
            ))}
          </div>

          {getModals()}
          
        </Fragment>)
      }
      {hasLoanOffer(permissions) &&
        <div
          className={
            cn(
              'layer dashboard_load-credit -space-down-lg-mobile -space-down-sm-desktop',
              notification ?
                '-space-up-xs_negative -space-up-md-mobile_negative' :
                '-space-up-xs-mobile -space-up-sm-desktop'
            )
          }
        >
          <Button
            xSize="full"
            color="white"
            className="dashboard_loan-credit-link -has-arrow -has-icon-before -no-border"
            href="/loan-offer"
          >
            <span className="icon -loan-credit">$</span>
            <div className="button_head font-weight-bold">
              You are qualified for ${currencyFormat(path(['user_credit_max', 'value'], permissions) / 100)} loan
            </div>
          </Button>          
        </div>
      }
    </div>
  );
};

DashboardNotifications.propTypes = {
  notification: PropTypes.shape({
    message: PropTypes.string.isRequired,
    redirectTo: PropTypes.string,
    signUpStep: PropTypes.string,
    bankAccount: types.BankAccountShape,
    sendEmail: PropTypes.bool,
    linkGunBroker: PropTypes.bool,
    showIdentityModal: PropTypes.bool,
    yodlee: PropTypes.bool,
    url: PropTypes.string,
    ssnModal: PropTypes.bool,
    einModal: PropTypes.bool,
    children: PropTypes.node,
  }),
  gbPopupShown: PropTypes.bool,
  permissions: types.EnabledStatesShape,
  isLoading: PropTypes.bool,
  yodleeInProgress: PropTypes.bool,
  setSignUpField: PropTypes.func,
  bankAccountFlow: PropTypes.shape(types.bankAccountReducerTypes),
  bankAccounts: PropTypes.arrayOf(types.BankAccountShape),
  sendVerificationEmail: PropTypes.func,
  setField: PropTypes.func,
  setBankAccountField: PropTypes.func,
  sendDocumentationEmail: PropTypes.func,
  addBankAccountInstant: PropTypes.func,
  addBankAccountMassive: PropTypes.func,
  userId: PropTypes.string,
  startVerification: PropTypes.func,
  updateUserSSN: PropTypes.func,
  updateEIN: PropTypes.func,
  userIsLoading: PropTypes.bool,
  updateUserNotice: PropTypes.func,
  notices: PropTypes.arrayOf(types.noticeShape),
  userBalance: PropTypes.string,
  transactionsPending: PropTypes.shape({
    data: PropTypes.arrayOf(types.TransactionShape),
    isLoading: PropTypes.bool
  })
};

const notificationSelector = ({ user: { user, ssn_entered, gbData, kycStatus }, wallet: { bankAccounts, wallet: { ein } }, ...state }) => {
  if (!user.email_verified) {
    return {
      message: 'Email verification required. Click to resend the verification link. Click to resend the verification link.',
      sendEmail: true,
    };
  }

  if (!user.phone_verified && user.phone_prompt) {
    return {
      message: 'Add your phone number ',
      redirectTo: '/signup',
      signUpStep: CONST.STEP_PHONE_NUMBER,
    };
  }

  if(kycStatus) {
    if(kycStatus[0].status === KYC_STATUS.PENDING) {
      return {
        message: 'Your identity verification is pending'
      };
    }

    if(kycStatus[0].status === KYC_STATUS.ERROR) {
      return {
        message: 'Failed to verify your identity. Please try again',
        redirectTo: '/signup',
        signUpStep: CONST.STEP_VERIFICATION_SELECT,
      };
    }
  }

  if (!user.name_confirmed && !user.identity_verified) {
    return {
      message: 'Complete your identity verification',
      redirectTo: '/signup',
      signUpStep: CONST.STEP_VERIFICATION_SELECT,
    };
  }

  if (!user.identity_verified && user.name_confirmed) {
    return {
      message: 'Complete your identity verification',
      redirectTo: '/signup',
      signUpStep: CONST.STEP_VERIFICATION_SELECT,
    };
  }

  const { can_skip_ssn, can_skip_ein } = user.enabled_states;
  if (!ein && can_skip_ein && can_skip_ein.value === false) {
    return {
      message: can_skip_ein.message,
      einModal: true
    };
  }

  if (!ssn_entered && can_skip_ssn && can_skip_ssn.value === false) {
    return {
      message: can_skip_ssn.message,
      ssnModal: true
    };
  }

  if ((!bankAccounts || bankAccounts.length === 0) && canAddBankAccountSelector(state)) {
    return {
      message: 'Add Bank Account',
      children: <ACHNotificationText />,
      yodlee: true,
    };
  }

  const bankAccount = find(propEq('amounts_verified', false), bankAccounts);
  if (bankAccount && canAddBankAccountSelector(state)) {
    return {
      message: 'Activate your Bank Account to start using it',
      bankAccount,
    };
  }

  return null;
};

const mapStateToProps = state => ({
  notification: notificationSelector(state),
  yodleeInProgress: state.dashboard.yodleeInProgress,
  bankAccountFlow: state.bankAccount,
  bankAccounts: state.wallet.bankAccounts,
  isLoading: state.dashboard.isLoading,
  permissions: state.user.user.enabled_states,
  gbPopupShown: state.user.gbData.popupIsOpened,
  kycSessionId: state.signUp.kycSessionId,
  kycStatus: state.user.kycStatus,
  userId: state.user.user.user_id,
  userIsLoading: state.user.isLoading,
  notices: state.user.user.notices,
  userBalance: userBalanceSelector(state),
  transactionsPending: state.transactions.transactionsPending
});

export default connect(mapStateToProps, {
  setSignUpField,
  sendVerificationEmail,
  setField,
  setBankAccountField,
  sendDocumentationEmail,
  addBankAccountInstant,
  addBankAccountMassive,
  startVerification,
  updateUserSSN,
  updateEIN,
  updateUserNotice
})(DashboardNotifications);

