import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Svg from '../svg';
import { NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import WalletIcon from '../../pages/wallet-select/wallet-icon';
import { OnDesktop } from '../breakpoints';

const MenuSecondary = ({
  walletType,
  initials,
  userName,
  logoutUser,
  isBankAccountsDisabled,
  isCreditCardsDisabled,
  hasLoanOffer,
}) => {
  const [dropdownDesktopMenuIsOpen, setDropdownDesktopMenuIsOpen] = useState(false);
  const _isMounted = useRef(false);  

  useEffect(() => {
    _isMounted.current = true;

    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <nav
      className="header-menu_body -secondary"
      onMouseEnter={() => setDropdownDesktopMenuIsOpen(true)}
      onMouseLeave={() => setDropdownDesktopMenuIsOpen(false)}
      onClick={() => {
        setDropdownDesktopMenuIsOpen(true);
        const clickListener = document.body.addEventListener('click', () => {
          _isMounted.current && setDropdownDesktopMenuIsOpen(false);
          document.body.removeEventListener('click', clickListener, false);
        }, false);
      }}
    >
      <OnDesktop>
        <WalletIcon className="header-menu_secondary-dropdown-trigger" type={walletType} initials={initials} />
      </OnDesktop>
      <CSSTransition
        in={dropdownDesktopMenuIsOpen}
        timeout={500}
        classNames=""
      >
        <div className="header-menu_body-content">
          <OnDesktop>
            <div className="header-menu_body-content-header">
              <WalletIcon type={walletType} initials={initials} />
              <div>
                <p className="-primary">
                  {userName}
                </p>
                <p className="-secondary">
                  {walletType}
                </p>
              </div>
              {/* <NavLink to="/wallet-settings" className="-wallet-settings">All wallets</NavLink> */}
            </div>
          </OnDesktop>
          {!isBankAccountsDisabled && (
            <NavLink className="header-menu_item -accounts" to="/bank-accounts">
              <Svg name="banknote" className="header-menu_item-icon" />
              Bank Accounts
            </NavLink>
          )}
          {!isCreditCardsDisabled && (
            <NavLink className="header-menu_item -credit-cards -accounts" activeClassName="-active" to="/credit-cards">
              <Svg name="credit-card" className="header-menu_item-icon" />
              Credit Cards
            </NavLink>
          )}
          <NavLink className="header-menu_item -accounts js-menu-item-wallet-settings" to="/wallet-settings">
            <Svg name="filter-bars" className="header-menu_item-icon" />
            Wallet Settings
          </NavLink>
          {hasLoanOffer && (
            <NavLink className="header-menu_item -accounts js-menu-item-wallet-settings" to="/loan-offer">
              <span className="header-menu_item-icon -offer">$</span>
              Loan Offer
            </NavLink>
          )}
          <a 
            href={process.env.REACT_APP_HELP_DESK_URL} 
            rel="noopener noreferrer"
            target="_blank" 
            className="header-menu_item -sub"
          >
            Customer Support
          </a>
          <a 
            href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL} 
            target="_blank"
            rel="noopener noreferrer"
            className="header-menu_item -sub"
          >
            User Agreement
          </a>
          <a href="#logout" className="header-menu_item -sub" onClick={logoutUser}>
            Log Out
          </a>
        </div>
      </CSSTransition>
    </nav>
  );
};

MenuSecondary.propTypes = {
  walletType: PropTypes.string,
  initials: PropTypes.string,
  logoutUser: PropTypes.func,
  isBankAccountsDisabled: PropTypes.bool,
  isCreditCardsDisabled: PropTypes.bool,
  hasLoanOffer: PropTypes.bool,
  userName: PropTypes.string,
};

export default MenuSecondary;
